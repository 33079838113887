import {
  Card,
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  Anchor,
  Space,
  DatePicker,
} from "antd";
import { useState } from "react";
const { Link } = Anchor;

const TODO = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      //   wrapperCol={{
      //     flex: 1,
      //   }}
    >
      <Form.Item
        valuePropName="checked"
        name="Discussed Reporting Requirements"
      >
        <Checkbox value="Discussed Reporting Requirements">
          Discussed{" "}
          {
            <a
              href="http://www.dcf.ks.gov/services/ees/Pages/Change-Reporting-Requirements.aspx"
              target="_blank"
            >
              Reporting Requirements
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Discussed SNAP-Ed">
        <Checkbox value="Discussed SNAP-Ed">
          Discussed{" "}
          {
            <a
              href="\\dbsmb\east\EES-Tracker\Roadmap\Files\New SNAP-Ed Marketing Piece DCF-SNAP-Ed.pdf"
              target="_blank"
            >
              SNAP-Ed
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="FA Optional Release of Information discussed ES-3103 "
      >
        <Checkbox value="FA Optional Release of Information discussed ES-3103 ">
          FA Optional Release of Information discussed{" "}
          {
            <a
              href="http://content.dcf.ks.gov/EES/KEESM/Forms/ES-3103FoodAssistanceOptionalReleaseOfInformationForm03-19.pdf"
              target="_blank"
            >
              ES-3103
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Provide Parent Provider Handbook SPANISH"
      >
        <Checkbox value="Provide Parent Provider Handbook SPANISH">
          Provide{" "}
          {
            <a
              href="http://www.ksqualitynetwork.org/parent-provider-partnership"
              target="_blank"
            >
              Parent Provider Handbook
            </a>
          }
          &ensp;{" "}
          {
            <a
              href="http://www.ksqualitynetwork.org/parent-provider-partnership-sp"
              target="_blank"
            >
              SPANISH
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Reviewed EDBC">
        <Checkbox value="Reviewed EDBC">Reviewed EDBC</Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Review and Send Notices (KEES WR Desk Aid)"
      >
        <Checkbox value="Review and Send Notices (KEES WR Desk Aid)">
          Review and Send Notices{" "}
          {
            <a
              href="http://dcfnet.dcf.ks.gov/EES/Documents/BPM/KEES Notice Cut and Paste.pdf"
              target="_blank"
            >
              (KEES WR Desk Aid)
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Pre-Auth Required">
        <Checkbox value="Pre-Auth Required">
          {
            <a
              href="https://dcfnet.dcf.ks.gov/EES/Documents/BPM/Pre-Authorization Checklist.docx"
              target="_blank"
            >
              Pre-Auth Required
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="CUSTOMER SCHEDULE">
        <Checkbox value="CUSTOMER SCHEDULE">CUSTOMER SCHEDULE</Checkbox>
      </Form.Item>
      <Row style={{ marginBottom: 10 }}>
        <label>Update the customer schedule list page</label>
      </Row>
    </Form>
  );
};
export default TODO;
