import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginValue: 'Signed Out',
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginStatus: (state,action) => {
      state.loginValue = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setLoginStatus } = loginSlice.actions

export default loginSlice.reducer
