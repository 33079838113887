import { PhoneOutlined, HomeOutlined, FileUnknownOutlined } from '@ant-design/icons';
import { PHONE_NUMBER_ASSOCIATIONS}  from '../../../literals';
import { Tooltip }  from 'antd';
function MatchPhoneToIcon(p){
    switch(p)
    {
      case PHONE_NUMBER_ASSOCIATIONS.HOME: 
            return <HomeOutlined />
      case PHONE_NUMBER_ASSOCIATIONS.CELLULAR:
        return <PhoneOutlined />
      default:
        return <FileUnknownOutlined />
    }
}
const PhoneIcon = (props) => {
    return <Tooltip placement="bottom" title={props.phoneType}> 
            {MatchPhoneToIcon(props.phoneType)}
        </Tooltip>;
}
 
export default PhoneIcon;
 