import { Card, Row, Space, Image,List } from "antd";
import React from "react";
import lLogo from "../images/guide1.png";
import { WifiOutlined } from "@ant-design/icons";

const Network = (props) => {
const data =[
    {
        title:(
            <>
                <WifiOutlined /> Network Error Guide 
            </>
        ),
        content:(
            <Row>
            <Row>
              <Space direction="vertical" >
                <p>
                  If <strong>Amazon Connect</strong> page displays any one of these error in the image below please follow 
                  these provided steps:
                </p>
                  <Image
                    className="img2"
                    preview={false}
                    width={550}
                    height={390}
                    src={lLogo}
                    alt="DCF Banner"
                  />
                  <br/>
                <ol>
                    <li>
                    In Chrome browser, click the three dots at the top-right and open 
                    <strong> Settings</strong>
                    </li>
                    <li>
                     Click <strong>Privacy and Security {">"} Clear browsing data </strong> 
                    </li>
                    <li>At the top, choose a time range. To delete everything, choose All 
                    Time</li>
                    <li>Check the boxes for <strong> “Cookies and other site data”</strong> and<strong> “Cached images and files”</strong>
                    </li>
                    <li>Click<strong> Clear data</strong>, and afterwards restart browser and computer</li>
                    <li>
                    Login to Amazon Connect and open the softphone to take/make 
                    calls. If the connectivity error is still prevalent, please send an 
                    email to {
                        <a
                          href="malito:Kansas-CC-Help@accenture.com"
                          target="malito:Kansas-CC-Help@accenture.com" rel="noreferrer"
                        >Kansas-CC-Help@accenture.com</a>} with the logs mentioned on log page.
                    </li>
                </ol>
                <p><strong>Note:</strong> These errors may also be attributed to the 
                Internet Service Provider (ISP) speed or sluggish download and upload 
                speeds from users' routers. Moreover, if the user is connected via a Virtual Private Network (VPN), 
                these errors may manifest frequently due to diminished downstream speed.</p>
                <br />
              </Space>
            </Row>
          </Row>
        )
    }
]
  return (
    <div >
        <List 
        dataSource ={data}
        renderItem={item =>(
            <List.Item>
                <Card title = {item.title} >{item.content}</Card>
            </List.Item>
        )}
        />
    </div>
  );
};
export default Network;
