export const esTemplates = [
    {
        value: "This is DCF Employment Services, we will be contacting you to complete your appointment in the next 3-5 minutes.",
        spanishValue:"Estos son los Servicios de Empleo del DCF, nos comunicaremos con usted para completar su cita en los próximos 3 a 5 minutos.",
        label: "ES Appointment Before Call: This is DCF Employment Services, we will be contacting you to complete your appointment in the next 3-5 minutes.",
    },
    {
        value: "This is your DCF Career Navigator, we just attempted to call you for your appt. We will try again in 3 to 5 minutes.",
        spanishValue:"Este es su Navegador de Carreras del DCF, acabamos de intentar llamarlo para su cita. Lo intentaremos nuevamente en 3 a 5 minutos.",
        label: "ES Appointment After Call: This is your DCF Career Navigator, we just attempted to call you for your appt. We will try again in 3 to 5 minutes.",
    }
]