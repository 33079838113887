import { Card, Row, Space, Image,List } from "antd";
import React from "react";
import lLogo from "../images/guide2.png";
import { AudioFilled } from "@ant-design/icons";
const Audio = (props) => {
const data =[
    {
        title:(
            <>
                <AudioFilled /> Audio Error Guide 
            </>
        ),
        content:(
            <Row>
            <Row>
              <Space direction="vertical" >
                <p>
                  Users experiencing microphone issues or receiving a pop-up message on
                  the <strong> Amazon Connect</strong> page, such as the one shown in the image below?
                  Please follow the provided steps to resolve the audio issue:
                </p>
                <Image
                    className="img2"
                    preview={false}
                    width={640}
                    height={340}
                    src={lLogo}
                    alt="DCF Banner"
                  />
                  <br/>
                  <ol>
                  <li>In Chrome browser, click the three dots at the top-right and open
                  <strong> Settings</strong>
                  </li>
                  <li>Click <strong> Privacy and Security {">"} Site Settings {">"} Microphone</strong></li>
                  <li>Delete the following website from the allowed list if exists: 
                  https://ks-covid-19-hotline.awsapps.com:443
                  </li>
                  <li>Restart browser</li>
                  <li>Try logging into Amazon Connect</li>
                  <li>When clicking on the phone icon and opening the softphone, a 
                      pop-up appears to allow microphone and camera access. Press 
                      <strong> Allow</strong>.</li>
                 <li>The issue should now be resolved.</li>
                 <li>If the microphone issue persists, please confirm your headset is 
                     working properly by logging into alternate pc/testing audio 
                    settings. If error is still prevalent, please send an email to 
                    Kansas-CC-Help@accenture.com obtaining logs mentioned in the log tab.
                 </li>
                </ol>
              </Space>
            </Row>
          </Row>
        )
    }
]
     return (
    <div >
        <List 
        dataSource ={data}
        renderItem={item =>(
            <List.Item>
                <Card title = {item.title} >{item.content}</Card>
            </List.Item>
        )}
        />
    </div>
  );
}
export default Audio;