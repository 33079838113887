export const customTemplates = [
    {
      value: "As a reminder, please provide the following items to DCF within 10 days: ",
      spanishValue:"Como recordatorio, proporcione los siguientes elementos al DCF dentro de los 10 días:",
      label: "Items Needed: As a reminder, please provide the following items to DCF within 10 days:",
    },
    {
      value: "To inquire about ______________ please visit/call ______________.",
      spanishValue:"Para preguntar sobre ______________ por favor visite/llame ______________.",
      label: "Inquiry: To inquire about ______________ please visit/call ______________.",
    },
    {
      value: "Your case number for Food Assistance, TANF or Child Care is ______________. You can check on your benefits in 3 easy steps. Call 1-888-369-4777. Follow the automated prompts. Provide your Case Number, last 4 digits of a case member’s social security number, date of birth of that same case member, and the zip code associated with your case.",
      spanishValue:"Su número de caso de Asistencia Alimentaria, TANF o Cuidado Infantil es ______________. Puede consultar sus beneficios en 3 sencillos pasos. Llame al 1-888-369-4777. Siga las indicaciones automatizadas. Proporcione su número de caso, los últimos 4 dígitos del número de seguro social de un miembro del caso, la fecha de nacimiento de ese mismo miembro del caso y el código postal asociado con su caso.",
      label: "FA,TANF,CC Case Number: Your case number for Food Assistance, TANF or Child Care is ______________. You can check on your benefits in 3 easy steps. Call 1-888-369-4777. Follow the automated prompts. Provide your Case Number, last 4 digits of a case member’s social security number, date of birth of that same case member, and the zip code associated with your case.",
    },
    {
      value: "Your case number for LIEAP is ______________. You can check on your benefits in 3 easy steps. Call 1-888-369-4777. Follow the automated prompts. Provide your Case Number, last 4 digits of a case member’s social security number, date of birth of that same case member, and the zip code associated with your case.",
      spanishValue:"Su número de caso de LIEAP es ______________. Puede consultar sus beneficios en 3 sencillos pasos. Llame al 1-888-369-4777. Siga las indicaciones automatizadas. Proporcione su número de caso, los últimos 4 dígitos del número de seguro social de un miembro del caso, la fecha de nacimiento de ese mismo miembro del caso y el código postal asociado con su caso.",
      label: "LIEAP Case Number: Your case number for LIEAP is ______________. You can check on your benefits in 3 easy steps. Call 1-888-369-4777. Follow the automated prompts. Provide your Case Number, last 4 digits of a case member’s social security number, date of birth of that same case member, and the zip code associated with your case.",
    },
    {
      value: "This is your DCF Career Navigator, we are attempting to contact you regarding your Employment Services case. Please contact us within 24hrs to provide good cause for missing your appointment on ______________. Failure to do so may result in case closure.",
      spanishValue:"Este es su Navegador de Carreras del DCF. Estamos intentando comunicarnos con usted con respecto a su caso de Servicios de Empleo. Comuníquese con nosotros dentro de las 24 horas para brindar una buena causa por haber faltado a su cita el ______________. No hacerlo puede resultar en el cierre del caso.",
      label: "Employment Services Missed Appointment: This is your DCF Career Navigator, we are attempting to contact you regarding your Employment Services case. Please contact us within 24hrs to provide good cause for missing your appointment on ______________. Failure to do so may result in case closure.",
    },

    {
      value: "This is a DCF Employment Services reminder, you have an appt with your DCF Career Navigator on ______________, at ______________. Please review your mail for further details.",
      spanishValue:"Este es un recordatorio de los Servicios de Empleo del DCF: tiene una cita con su Navegador de Carreras del DCF el ______________, a las ______________. Por favor revise su correo para más detalles.",
      label: "Employment Services Appointment: This is a DCF Employment Services reminder, you have an appt with your DCF Career Navigator on ______________, at ______________. Please review your mail for further details."
    },
    {
      value: "As a reminder, please provide the following items to your DCF Career Navigator by ______________: (items).",
      spanishValue:"Como recordatorio, proporcione los siguientes elementos a su Navegador de Carreras del DCF antes del ______________: (items).",
      label: "Employment Services Items: As a reminder, please provide the following items to your DCF Career Navigator by ______________: (items).",
    },
    {
      value: "To Contact your DCF Career Navigator, ______________, please call/email ______________.",
      spanishValue:"Para comunicarse con su orientador profesional del DCF, ______________, llame o envíe un correo electrónico a ______________.",
      label: "Employment Services Contact: To Contact your DCF Career Navigator, ______________, please call/email ______________."
    },
    
  ];
  