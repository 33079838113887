import { Row, Col, Form, Checkbox, Input, Select, Radio } from "antd";
import { useState } from "react";
const { Option } = Select;

const EXPENSELIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          EXPENSE LIST (FA, TANF)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Do you rent, own or are purchasing your home?"
        label="Do you rent, own or are purchasing your home?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="If renting, is this subsidized housing, Section 8, HUD, other?"
        label="If renting, is this subsidized housing, Section 8, HUD, other?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="What’s your monthly rent, mortgage, or obligation?"
        label="What’s your monthly rent, mortgage, or obligation?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item valuePropName="checked" name="Collateral Contact">
        <Row>
          <label>
            (If Questionable-What is your Landlords name, address and telephone
            number?)
          </label>
        </Row>
        <Row>
          <Checkbox value="Collateral Contact1">Collateral Contact?</Checkbox>
        </Row>
      </Form.Item>
      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Telephone Number:" label="Telephone Number:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Business Name:" label="Business Name:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Taxes/Insurance amount paid separately from mortgage payment?"
        label="Taxes/Insurance amount paid separately from mortgage payment?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you have Heating or Cooling expenses (paid separately from rent) or did you receive LIEAP in the last 12 months?"
        label="Do you have Heating or Cooling expenses (paid separately from rent) or did you receive LIEAP in the last 12 months?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <label>IF NO:</label>
      </Row>
      <Row>
        <label>
          What utility expenses do you pay?
          {
            <a
              href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm7226.htm"
              target="_blank"
            >
              KEESM 7226.3
            </a>
          }
        </label>
      </Row>
      <Form.Item name="What utility expenses do you pay? KEESM 7226.3">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Does anyone help you pay any of these expenses? Gift, Loan, or Vendor?"
        label="Does anyone help you pay any of these expenses? Gift, Loan, or Vendor?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Row gutter={4}>
        <Col span={8}>
          <Form.Item name="Name:" label="Name:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="Expense/Amount:" label="Expense/Amount:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="Comments1" label="Comments">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Does the household’s expenses exceed their income?"
        label="Does the household’s expenses exceed their income?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="If yes, how are you paying your expenses?"
        label="If yes, how are you paying your expenses?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Comments2" label="Comments">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Pending for:" label="Pending for:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default EXPENSELIST;
