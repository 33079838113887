import { Select, Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import { useState } from "react";
const { Option } = Select;
const PREGNANCYLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      style={{ maxWidth: "100%" }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          PREGNANCY LIST (All Programs)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            valuePropName="checked"
            name="Is anyone in the household pregnant?"
          >
            <Checkbox value="Is anyone in the household pregnant?">
              Is anyone in the household pregnant?
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* <Form.Item name="Due Date" label="Due Date">
            <DatePicker
              style={{ width: "100%" }}
              allowClear
              // format={"YYYY-MM-DD"}
            />
          </Form.Item> */}
        </Col>
      </Row>
      <Row>
        <Form.Item name="Comments" label="Comments">
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="Pending for:" label="Pending for:">
          <Input style={{ width: "100%" }} />
        </Form.Item>
      </Row>
    </Form>
  );
};
export default PREGNANCYLIST;
