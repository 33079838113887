import {
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  DatePicker,
  Anchor,
  Radio,
} from "antd";
const { Link } = Anchor;

const TANFDT = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="left"
      layout="vertical"
      labelWrap
      // wrapperCol={{
      //   flex: 1,
      // }}
    >
      <Form.Item
        valuePropName="checked"
        name="Received signed ES-3100DT “Drug Testing Acknowledgement”  ES-3100DT ES-3100DT (SPANISH)(NEW APPLICATIONS ONLY)"
      >
        <Checkbox value="Received signed ES-3100DT “Drug Testing Acknowledgement”  ES-3100DT ES-3100DT (SPANISH)(NEW APPLICATIONS ONLY)">
          Received signed ES-3100DT “Drug Testing Acknowledgement
        </Checkbox>
      </Form.Item>
      <Row>
        <Link style={{ DisplayFormat: "inline-block" }}>
          {
            <a
              style={{ DisplayFormat: "inline-block" }}
              href="https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100DTDrugTestingAcknowledgement06-20_Fillable.pdf"
              target="_blank"
            >
              ES-3100DT
            </a>
          }
          &ensp; &ensp;
          {
            <a
              style={{ DisplayFormat: "inline-block" }}
              href="https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100DTDrugTestingAcknowledgement06-20_Fillable.pdf"
              target="_blank"
            >
              ES-3100DT (SPANISH)
            </a>
          }
        </Link>
        (NEW APPLICATIONS ONLY)
      </Row>
      <Form.Item
        name="Have you, or anyone you are applying for been convicted of a felony drug crime since July 1, 2013?"
        label="Have you, or anyone you are applying for been convicted of a felony drug crime since July 1, 2013?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row gutter={[2, 2]}>
        <Col span={12}>
          <Form.Item name="Name" label="Name">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="Date of Conviction" label="Date of Conviction">
            <DatePicker
              style={{ width: "100%" }}
              allowClear
              format={"YYYY-MM-DD"}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="What was the offense?" label="What was the offense?">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Where was the court of conviction?"
        label="Where was the court of conviction? "
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default TANFDT;
