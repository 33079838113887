import { Card, Row, Col, Space, Badge, Image, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Network from "../Troubleshoot/network"
import Audio from "../Troubleshoot/audio"
import Login from "../Troubleshoot/login"
import Logs from "../Troubleshoot/logs"
import { Layout, Menu, Tabs } from "antd";

const Guide = (props) => {
  const [textValue, setTextValue] = useState("");
  const loginStatus = useSelector((state) => state.login.loginValue);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("1");
  const [signedIn, setSignedIn] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [contactID, setContactID] = useState("");
  const [contactEnded, setContactEnded] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  console.log("Login Value is: ", loginStatus);
  const onChange = (key) => {
    console.log(key);
  };

  window.addEventListener("loggedIn", function () {
    console.log("fooLoggedIn!");
    setSignedIn(true);
  });

  window.addEventListener("loggedOut", function () {
    console.log("fooLoggedOut!");
    setSignedIn(false);
  });

  window.addEventListener(
    "contactEvent",
    async function (e) {
      console.log("Customer Number: ", e.detail.customerNo);
      var customerNumber = e.detail.customerNo;
      var contactID = e.detail.contactID;
      setCustomerNumber(customerNumber);
      setContactID(contactID);
    },
    false
  );

  window.addEventListener(
    "contactEnded",
    async function (e) {
      // console.log("Contact Ended: ", e.detail.contactEnded);
      setContactEnded(true);
      setCustomerNumber("");
      setContactID("");
      setCopiedToClipboard(false);
    },
    false
  );

  window.addEventListener(
    "refreshContact",
    async function (e) {
      // console.log("Contact Ended: ", e.detail.contactEnded);
      setContactEnded(false);
    },
    false
  );

  useEffect(() => {
    console.log(window);
    window["onload"]();
  }, []);

  useEffect(() => {
    console.log("UGHDN WindowSignedIn: ", window.isSignedIn);
    if (window["isSignedIn"] === "Signed In") {
      console.log("WIIIINDDOWW RESULT IS SIGNED IN");
    } else {
      console.log("WIIIINDDOWW RESULT IS SIGNED OUT");
    }
  }, [window["isSignedIn"]]);

  const sizes = ["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"];

  const tab_items = [
    {
      key: "1",
      label: <strong>Network Error</strong>,
      children: ( <Network />),
    },
    {
      key: "2",
      label: <strong>Audio Error</strong>,
      children: (< Audio />),
    },
    {
      key: "3",
      label: <strong>Login Error</strong>,
      children: (< Login />),
    },
    {
      key: "4",
      label: <strong>Logs</strong>,
      children: (< Logs />),
    },
  ];
  

  return (
    <div style={{ maxHeight: "100vh", overflow: "auto" }}>
        <Card
          hoverable
        >
        <Tabs
            defaultActiveKey={[1]}
            items={tab_items}
            onChange={onChange}
            style={{marginTop:'-15px'}}
            tabBarStyle={{backgroundColor: '#4a4a4a',paddingLeft:30}}
        />
        </Card>
    </div>
  );
};
export default Guide;