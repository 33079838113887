import { Card, Row, Space, Image,List } from "antd";
import React from "react";
import lLogo from "../images/guide3.png";
import { UserOutlined } from "@ant-design/icons";
import './style.css'
const Login = (props) => {
const data =[
    {
        title:(
            <>
                <UserOutlined /> Login Error Guide 
            </>
        ),
        content:(
            <Row>
            <Row>
              <Space direction="vertical" >
                <p>
                  If you are encountering difficulty with logging in or repeatedly
                experiencing Server Errors on the <strong> Amazon Connect </strong>page,
                please adhere to the following steps to rectify the issue:
                </p>
                <Image
                    className="img1"
                    preview={false}
                    height={395}
                    src={lLogo}
                    alt="DCF Banner"
                  />
                  <br/>
                  <ol>
                  <li> Ensure you are using Google Chrome or Firefox to access page
                  </li>
                  <li> If you are a new agent, please note that the sign-in link is only valid for 24 hours. If the link expires, kindly contact us via email: 
                       {
                        <a
                          href="malito:Kansas-CC-Help@accenture.com"
                          target="malito:Kansas-CC-Help@accenture.com" rel="noreferrer"
                        > Kansas-CC-Help@accenture.com</a>} to reset your password and proceed with the sign-in process.
                  </li>
                  <li> If steps 1 and 2 are fulfilled and issue persists, please 
                       follow the additional steps below:
                       
                       <ol>
                        <li> Close all the sessions, proper logout, and re-login attempt to 
                            Connect
                        </li>
                        <li> Restart computer</li>
                        <li> Clear the browser cache using the steps below.
                         <ol>
                         <li> Click <strong>Privacy and Security {">"} Clear browsing data </strong> </li>
                        <li> At the top, choose a time range. To delete everything, choose All 
                            Time</li>
                        <li>Check the boxes for <strong> “Cookies and other site data”</strong> and<strong> “Cached images and files”</strong>
                        </li>
                        <li>Click<strong> Clear data</strong>, and afterwards restart browser</li>
                        <p><strong> Note:</strong> If the actions are still unsuccessful, please send an email to the 
                           support team after obtaining logs mentioned on the log page.</p>
                         </ol> 
                       </li>
                       </ol>
                  </li>
                </ol>
              </Space>
            </Row>
          </Row>
        )
    }
]
     return (
    <div >
        <List 
        dataSource ={data}
        renderItem={item =>(
            <List.Item>
                <Card title = {item.title} >{item.content}</Card>
            </List.Item>
        )}
        />
    </div>
  );
}
export default Login;