import { Card, Row, Col, Form, Checkbox, Input, Select, Radio } from "antd";
import { useState } from "react";
const { Option } = Select;

const ScreenName_VR = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={12} style={{ fontWeight: "bold" }}>
          VOTER REGISTRATION
        </Col>
        <Col span={4}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        MUST BE VERBALLY ASKED AND CLIENT RESPONSE RECORDED (At Application
        ONLY)
      </Row>
      <Form.Item
        name="Would you like to register to vote?"
        label="Would you like to register to vote?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="Accepted">Accepted</Radio>
          <Radio value="Declined">Declined</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Checked Voter Registration Page in KEES to confirm update"
      >
        <Checkbox
          value="Checked Voter Registration Page in KEES to confirm update
"
        >
          Checked Voter Registration Page in KEES to confirm update
        </Checkbox>
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default ScreenName_VR;
