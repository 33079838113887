import { Row, Col, Form, Checkbox, Input, Select } from "antd";
import { useState } from "react";
const { Option } = Select;
const RELATIONSHIPLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          RELATIONSHIP LIST (All Programs)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <label>
        Are you a relative of the child/ren other than the parent?{" "}
        {
          <a
            href="https://content.dcf.ks.gov/ees/KEESM/Appendix/T-6RelationshipChart05-17.pdf"
            target="_blank"
          >
            T-6 Relationship Chart
          </a>
        }
      </label>
      <Form.Item
        valuePropName="checked"
        name="Specify how relationship was determined for a caretaker TANF case?"
      >
        <Checkbox
          value="Specify how relationship was determined for a caretaker TANF case?
"
        >
          Specify how relationship was determined for a caretaker TANF case?
        </Checkbox>
      </Form.Item>
      <Row>
        <label>
          *VERIFY ALL RELATIONSHIPS HAVE BEEN ESTABLISHED AND ARE RECORDED
        </label>
      </Row>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default RELATIONSHIPLIST;
