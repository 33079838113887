import { Col, Row, ThemeProvider } from "react-bootstrap";
import CustomerProfile from "../components/CustomerProfile/CustomerProfile";
import SMSForm from "../components/SMSForm";
import Metrics from "../components/Metrics";
import QueueStatus from "../components/QueueStatus";
import { DesktopOutlined, CopyOutlined, CopyFilled, SettingOutlined } from "@ant-design/icons";
import { Layout, Menu, Tabs, Alert } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Helmet} from "react-helmet";
import copy from 'copy-to-clipboard';
import { Input, Card, Tooltip, Segmented } from "antd";
import ScriptsForm from "../components/ContactForm/Scripts";
import BPMForm from "../components/ContactForm/BPM";
import CustomerForm from "../components/ContactForm/CustomerForm";
import Guide from "../components/Troubleshoot/guide"
import Marquee from 'react-fast-marquee';
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";



const { Header, Sider, Footer, Content } = Layout;
const idValues = ['0ea9defc-7300-4742-be6f-c7a64efd5580','b167c35b-6433-496d-b61e-e9a5171ad11c','a80506a8-b51d-4e3e-869d-4032c9bd526c','ae9e7e46-ca84-46da-be8f-1a248506adaa','993eadd7-6ada-40a8-9366-417e8872b60e']
// const idValues = ['2edf1e97-f954-4c81-936c-3b527285be78','a80506a8-b51d-4e3e-869d-4032c9bd526c','ae9e7e46-ca84-46da-be8f-1a248506adaa','993eadd7-6ada-40a8-9366-417e8872b60e']
// const idValues = process.env.KEYS;


function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Home", "1", <DesktopOutlined />),
  getItem("Troubleshooting Guide", "2", <SettingOutlined/>)
];

const supervisorItems = [
  getItem("Home", "1", <DesktopOutlined />),
  getItem("Monitor", "2", <DesktopOutlined />),
];

const App = () => {
  const loginStatus = useSelector((state) => state.login.loginValue);
  const [agentName, setAgentName] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("1");
  const [signedIn, setSignedIn] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [contactID, setContactID] = useState("");
  const [agentID, setAgentID] = useState("");
  const [queue, setQueue] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [activeKeyForm, setActiveKeyForm] = useState("1");
  const [activeKeyScript, setActiveKeyScript] = useState("1");
  const [activeKeyAdmin, setActiveKeyAdmin] = useState("1");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [contactEnded, setContactEnded] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  console.log("Login Value is: ", loginStatus);

  const getSecret = async() => {
    const secret_name = "customccp_apikey_prod";
    const client = new SecretsManagerClient({
      region: "us-west-2",
    });

    let response;

    try {
      response = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
        })
      );
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }

    const secret = response.SecretString;
    console.log("SECRET: ", secret)
  }

  const onChangeForm = (key) => {
    console.log(key);
    setActiveKeyForm(key)
  };

  const onChangeScript = (key) => {
    console.log(key);
    setActiveKeyScript(key)
  };

  const onChangeAdmin = (key) => {
    console.log(key);
    setActiveKeyAdmin(key)
  };

  const tab_items = [
    {
      key: "1",
      label: "Text to Phone",
      children: (
        <SMSForm
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "Interview Form",
    //   children: <CustomerForm
    //     agentName={agentName}
    //     caseNumber={caseNumber}
    //     customerNumber={customerNumber}
    //     queue={queue}
    //   />
    //   // disabled: contactID !== "" ? false : true,
    // }
  ];

  

  const admin_items = [
    {
      key: "1",
      label: "Adjust Capacity",
      children: (
        <Metrics
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "HSA Greeter Script",
    //   children: (
    //     <ScriptsForm/>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "BPM SCRIPTS AND DOCUMENTATION TEMPLATE",
    //   children: <BPMForm/>,
    //   // disabled: contactID !== "" ? false : true,
    // }
  ];


  const script_items = [
    {
      key: "1",
      label: "Queue Status",
      children: (
        <QueueStatus
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "HSA Greeter Template",
    //   children: (
    //     <ScriptsForm/>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "BPM SCRIPTS AND DOCUMENTATION TEMPLATE",
    //   children: <BPMForm/>,
    //   // disabled: contactID !== "" ? false : true,
    // },
    
  ];

  const setIndex = (e) => {
    if (e.key == "1") {
      setSelectedIndex("1");
    } else if (e.key == "2") {
      setSelectedIndex("2");
    }
  };

  window.addEventListener("loggedIn", function () {
    console.log("fooLoggedIn!");
    setSignedIn(true);
  });

  window.addEventListener("loggedOut", function () {
    console.log("fooLoggedOut!");
    setSignedIn(false);
  });

  window.addEventListener(
    "agentEvent",
    async function (e) {   
      var agentID = e.detail.id;      
      setAgentID(agentID);
      var agentNameVal = e.detail.agentName;      
      console.log("NAME OF AGENT: ", agentNameVal)
      setAgentName(agentNameVal);
    },
    false
  );
  

  useEffect(() => {
    console.log("NAME FROM USEEFFECT: ", agentName);
  }, [agentName]);

  window.addEventListener(
    "contactEvent",
    async function (e) {
      console.log("Customer Number: ", e.detail.customerNo);
      var customerNumber = e.detail.customerNo;
      var contactID = e.detail.contactID;
      setCustomerNumber(customerNumber);
      setContactID(contactID);
    },
    false
  );

  window.addEventListener(
    "attributeEvent",
    async function (e) {
      console.log("Attributes: ", e.detail.attributes);
      // var isAuthenticated = e.detail.attributes.authenticated.value || '';
      var caseNumber = e.detail.attributes.caseNumber.value || '';
      var coloredQueue = e.detail.attributes.finalQueue.value || '';
      // console.log("IS AUTHENTICATED: ", isAuthenticated)
      console.log("coloredQueue: ", coloredQueue)
      // setIsAuthenticated(isAuthenticated);
      setQueue(coloredQueue)
      setCaseNumber(caseNumber)
      
      if(coloredQueue === "Green" || coloredQueue === "Red"){
        console.log("Setting active queue to interview forms")
        setActiveKeyForm("2")
        setActiveKeyScript("3")
        setActiveKeyAdmin("3")
      }
  
    },
    false
  );


  // 2edf1e97-f954-4c81-936c-3b527285be78


  window.addEventListener(
    "contactEnded",
    async function (e) {
      // console.log("Contact Ended: ", e.detail.contactEnded);
      setContactEnded(true);
      setCustomerNumber("");
      setContactID("");
      setCopiedToClipboard(false);
      setIsAuthenticated(false);
      setActiveKeyForm("1")
      setActiveKeyScript("1")
      setActiveKeyAdmin("1")
      setQueue("")
    },
    false
  );
  
  window.addEventListener(
    "refreshContact",
    async function (e) {
      // console.log("Contact Ended: ", e.detail.contactEnded);
      setContactEnded(false);
    },
    false
  );

  useEffect(() => {
    getSecret()
    console.log(window);
    window["onload"]();
  }, []);

  useEffect(() => {
    console.log("UGHDN WindowSignedIn: ", window.isSignedIn);
    if (window["isSignedIn"] == "Signed In") {
      console.log("WIIIINDDOWW RESULT IS SIGNED IN");
    } else {
      console.log("WIIIINDDOWW RESULT IS SIGNED OUT");
    }
  }, [window["isSignedIn"]]);

  useEffect(()=>{
    console.log(queue)
  }, [queue])

  const sizes = ["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {/* style={{backgroundColor: '#26303B'}} */}
      <Sider
        trigger={
          <div style={{ backgroundColor: "#26303B" }}>
            {collapsed ? ">" : "<"}
          </div>
        }
        style={{ backgroundColor: "#26303B" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="center-content">
          <a href="index.html">
            <i className="lilly-icon lilly-icon-logo"></i>
          </a>
        </div>
        <div className="center-content">
          {collapsed === false && (
            <div className="header-title">Amazon Connect</div>
          )}
        </div>

        
        <Menu
          theme="dark"
          style={{ backgroundColor: "#26303B" }}
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          onClick={setIndex}
        />
      </Sider>

      
      
        <Layout className="site-layout">
          <div className="site-layout" style={{display:  selectedIndex === "1" ? 'block' : 'none' }}>
          <Header
            className="site-layout-header-background"
            style={{
              padding: 0,
            }}
          />
          <Content
            style={{
              margin: "7px 7px",
            }}
          >


            
            <div className="site-layout-background">
            {/* <Alert
              banner
              type="info"
              message={
                <Marquee pauseOnHover gradient={false}>
                  I can be a React component, multiple React components, or just some text.
                </Marquee>
              }
            /> */}
              <ThemeProvider breakpoints={sizes}>

                <Row style={{ minHeight: "50vh" }}>
                  
                  <Col>
                    <div className="ccpcontainer" id="ccp-container"></div>
                    {/* {
                      queue == "Red" ?
                       <>
                       <div className="contact-id-red">
                          Contact ID: {contactID} {' '}
                          {copiedToClipboard ? 
                          <>
                          <CopyFilled />
                          </>
                            :
                            <>
                            <CopyOutlined onClick={()=>{
                              if(contactID.length > 0){
                                navigator.clipboard.writeText(contactID);
                                setCopiedToClipboard(true);
                              }
                              
                              }} />
                            </>
                          }
                        </div>
                       </>
                      :
                      queue == "Green" ?
                      <>
                      <div className="contact-id-green">
                          Contact ID: {contactID} {' '}
                          {copiedToClipboard ? 
                          <>
                          <CopyFilled />
                          </>
                            :
                            <>
                            <CopyOutlined onClick={()=>{
                              if(contactID.length > 0){
                                navigator.clipboard.writeText(contactID);
                                setCopiedToClipboard(true);
                              }
                              
                              }} />
                            </>
                          }
                        </div>
                      </>
                      :
                      queue == "Blue" ?
                      <>
                      <div className="contact-id-blue">
                          Contact ID: {contactID} {' '}
                          {copiedToClipboard ? 
                          <>
                          <CopyFilled />
                          </>
                            :
                            <>
                            <CopyOutlined onClick={()=>{
                              if(contactID.length > 0){
                                navigator.clipboard.writeText(contactID);
                                setCopiedToClipboard(true);
                              }
                              
                              }} />
                            </>
                          }
                        </div>
                      </>
                      :
                      queue == "IR" ?
                      <>
                      <div className="contact-id-ir">
                          Contact ID: {contactID} {' '}
                          {copiedToClipboard ? 
                          <>
                          <CopyFilled />
                          </>
                            :
                            <>
                            <CopyOutlined onClick={()=>{
                              if(contactID.length > 0){
                                navigator.clipboard.writeText(contactID);
                                setCopiedToClipboard(true);
                              }
                              
                              }} />
                            </>
                          }
                        </div>
                      </>
                      :
                      queue == "Purple" ?
                      <>
                      <div className="contact-id-purple">
                          Contact ID: {contactID} {' '}
                          {copiedToClipboard ? 
                          <>
                          <CopyFilled />
                          </>
                            :
                            <>
                            <CopyOutlined onClick={()=>{
                              if(contactID.length > 0){
                                navigator.clipboard.writeText(contactID);
                                setCopiedToClipboard(true);
                              }
                              
                              }} />
                            </>
                          }
                        </div>
                      </>
                      :
                      <>
                        <div className="contact-id">
                          Contact ID: {contactID} {' '}
                          {copiedToClipboard ? 
                          <>
                          <CopyFilled />
                          </>
                            :
                            <>
                            <CopyOutlined onClick={()=>{
                              if(contactID.length > 0){
                                navigator.clipboard.writeText(contactID);
                                setCopiedToClipboard(true);
                              }
                              
                              }} />
                            </>
                          }
                        </div>
                      </>
                    } */}
                    

                    <div className="contact-id">
                      Contact ID: {contactID} {' '}
                      {copiedToClipboard ? 
                      <>
                      <CopyFilled />
                      </>
                        :
                        <>
                        <CopyOutlined onClick={()=>{
                          if(contactID.length > 0){
                            navigator.clipboard.writeText(contactID);
                            setCopiedToClipboard(true);
                          }
                          
                          }} />
                        </>
                      }
                    </div>
                    
                  </Col>
                  <Col>
                    {signedIn ? (
                      <>
                        {/* <SMSForm
                          customerNumber={customerNumber}
                          contactEnded={contactEnded}
                          contactID={contactID}
                        /> */}
                        <Tabs
                          defaultActiveKey={[1]}
                          activeKey={activeKeyForm}
                          items={tab_items}
                          onChange={onChangeForm}
                          tabBarStyle={{backgroundColor:'#4a4a4a',paddingLeft:30}}
                          
                        />
                      </>
                    ) : (
                      <>
                      <Card
                        title="Please sign into Amazon Connect"
                      ></Card>
                      </>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    {signedIn ? (
                      <>
                        <CustomerProfile
                          customerNumber={customerNumber}
                          contactEnded={contactEnded}
                          contactID={contactID}
                        />
                      </>
                    ) : (
                      <>
                      <Card
                        title="Please sign into Amazon Connect"
                      ></Card>
                      
                      
                      </>
                    )}
                  </Col>
                  
                  {idValues.includes(agentID) ?
                  <>
                  <Col>
                    {signedIn ? (
                      <>
                        <Tabs
                          defaultActiveKey={[1]}
                          activeKey={activeKeyAdmin}
                          items={admin_items}
                          onChange={onChangeAdmin}
                          tabBarStyle={{backgroundColor:'#4a4a4a',paddingLeft:30}}
                          
                        />
                      </>
                    ) : (
                      <>
                      <Card
                        title="Please sign into Amazon Connect"
                      ></Card>
                      
                      
                      </>
                    )}
                  </Col>
                  </>
                  :
                  <>
                  <Col>
                    {signedIn ? (
                      <>
                        <Tabs
                          defaultActiveKey={[1]}
                          activeKey={activeKeyScript}
                          items={script_items}
                          onChange={onChangeScript}
                          tabBarStyle={{backgroundColor:'#4a4a4a',paddingLeft:30}}
                          
                        />
                      </>
                    ) : (
                      <>
                      <Card
                        title="Please sign into Amazon Connect"
                      ></Card>
                      
                      
                      </>
                    )}
                  </Col>
                   
                  </>
                  }
                  
                </Row>
              </ThemeProvider>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            EES-CCP ©2022
          </Footer>
          </div>
          <div className="site-layout" style={{display:  selectedIndex === "2" ? 'block' : 'none' }}>
            <Guide/>
          </div>
        </Layout>

    </Layout>
  );
};

export default App;
