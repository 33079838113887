import {
  Card,
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  Anchor,
  Space,
  DatePicker,
} from "antd";
import { useState } from "react";
const { Link } = Anchor;

const RUNEDBC = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item
        valuePropName="checked"
        name="Review all case details and ensure results are accurate.DO NOT accept incorrect EDBC results."
      >
        <Checkbox value="Review all case details and ensure results are accurate.DO NOT accept incorrect EDBC results.">
          Review all case details and ensure results are accurate.DO NOT accept
          incorrect EDBC results.
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Review the template, ensure all discrepancies are noted and the template is complete. (KEES WR Desk Aid)"
      >
        <Checkbox value="Review the template, ensure all discrepancies are noted and the template is complete. (KEES WR Desk Aid)">
          Review the template, ensure all discrepancies are noted and the
          template is complete.{" "}
          {
            <a
              href="http://dcfnet.dcf.ks.gov/EES/Documents/BPM/KEES Notice Cut and Paste.pdf"
              target="_blank"
            >
              (KEES WR Desk Aid)
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Make sure all programs are authorized and processed through come up month."
      >
        <Checkbox value="Make sure all programs are authorized and processed through come up month.">
          Make sure all programs are authorized and processed through come up
          month.
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Ensure all Notices, Requests, and Forms are sent."
      >
        <Checkbox value="Ensure all Notices, Requests, and Forms are sent.">
          Ensure all Notices, Requests, and Forms are sent.
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Update the task(s) in KEES.">
        <Checkbox value="Update the task(s) in KEES.">
          Update the task(s) in KEES.
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Update ALL E-apps with correct status."
      >
        <Checkbox value="Update ALL E-apps with correct status.">
          Update ALL E-apps with correct status.
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Only use Immediate Issuance for Food Assistance if the application is EXPEDITED and you’re processing benefits on the 7th day."
      >
        <Checkbox value="Only use Immediate Issuance for Food Assistance if the application is EXPEDITED and you’re processing benefits on the 7th day.">
          Only use Immediate Issuance for Food Assistance if the application is
          EXPEDITED and you’re processing benefits on the 7th day.
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Check that Review and IR / 12 Month Reporting page is correctly updated and EDBC run reason is correct."
      >
        <Checkbox value="Check that Review and IR / 12 Month Reporting page is correctly updated and EDBC run reason is correct.">
          Check that Review and IR / 12 Month Reporting page is correctly
          updated and EDBC run reason is correct.
        </Checkbox>
      </Form.Item>
      <Row style={{ marginTop: "10px", fontWeight: "bold" }}>
        Authorized Benefits
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Form.Item
          valuePropName="checked"
          name="INFORMED CLIENT OF BENEFIT AMOUNT AND WHEN BENEFITS WILL BE AVAILABLE FOR USE"
        >
          <Checkbox value="INFORMED CLIENT OF BENEFIT AMOUNT AND WHEN BENEFITS WILL BE AVAILABLE FOR USE">
            INFORMED CLIENT OF BENEFIT AMOUNT AND WHEN BENEFITS WILL BE
            AVAILABLE FOR USE
          </Checkbox>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default RUNEDBC;
