export const customDropdown = [
    {
        value: "LIEAP",
        spanishValue:"LIEAP",
        name: "LIEAP",
        link: "https://cssp.kees.ks.gov/apspssp/sspNonMed.portal or +1-888-369-4777 and press 4"
    },
    {
        value: "Medical Assistance",
        spanishValue:"Medical Assistance",
        name: "Medical Assistance",
        link: "https://kancare.ks.gov/ or +1-800-792-4884"
    },
    {
        value: "Rehabilitation Services",
        spanishValue:"Rehabilitation Services",
        name: "Rehabilitation Services",
        link: "https://www.dcf.ks.gov/services/RS/Pages/Employment-Services.aspx or +1-833-765-2003"
    },
    {
        value: "EBT Cards",
        spanishValue:"EBT Cards",
        name: "EBT Cards",
        link: "https://www.ebtedge.com/gov/portal/PortalHome.do or +1-800-997-6666"
    },
    {
        value: "Child Support Services",
        spanishValue:"Child Support Services",
        name: "Child Support Services",
        link: "https://www.dcf.ks.gov/services/CSS/Pages/ChildSupportCustomerServiceCenter.aspx or +1-888-757-2445"
    }
  ];
  