export const queueDropdown = [
    {
        value: "Red Queue",
        name: "Red Queue",
    },
    {
        value: "Green Queue",
        name: "Green Queue",
    },
    {
        value: "Blue Queue",
        name: "Blue Queue",
    },
    {
        value: "IR Queue",
        name: "IR Queue",
    },
    {
        value: "Purple Queue",
        name: "Purple Queue",
    },
    {
        value: "HSA Queue",
        name: "HSA Queue",
    }
  ];
  