import {MONTHS} from './literals';
export default function ConvertToFormUrlEncoded(obj)
{
    var formBody = [];
    for (var property in obj) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(obj[property]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
}
/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function GroupByProperty<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function GroupByProperty(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


export function GroupByProperties( list , propArray)
{
    var map = new Map();
    list.forEach((item) => {
        const key = JSON.stringify(propArray(item));
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


export function ConvertToPrettyDate(date){
    
    if(date != undefined){
        console.log("date is defined");
        console.log(date);
        date = date.toString().replace("_","-");
    }
    else{
        console.log("date is undefined");
        console.log(date);
    }
    return date
    // console.log(date);
    // const d = date && new Date(date);
    // console.log(d);
    // return d && `${MONTHS[d.getMonth()]} ${d.getDay()}, ${d.getFullYear()}`
}

export function DistinctByProperty(list, keyGetter){
    const result = [];
    const map = new Map();
    for (const item of list) {
        const key = keyGetter(item);
        if(!map.has(key)){
            map.set(key, true);    // set any value to Map
            result.push(item);
        }
    }
    return result;
}

export function DistinctByProperties(list, propArray){
    const result = [];
    const map = new Map();
    for (const item of list) {
        const key = JSON.stringify(propArray(item));
        if(!map.has(key)){
            map.set(key, true);    // set any value to Map
            result.push(item);
        }
    }
    return result;
}


export function domElement(className) {
    return document.getElementsByClassName(className)[0];
  }
  
  export function domShow(className) {
    const element = domElement(className);
    element.style.display = 'flex';
    element.setAttribute('show', 'true');
  }
  
  export function domHide(className) {
    const element = domElement(className);
    element.style.display = 'none';
    element.setAttribute('show', 'false');
  }

  