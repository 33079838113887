import { Row, Col, Form, Checkbox, Input, Select, Radio } from "antd";
const { Option } = Select;
const RESOURCELIST = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          RESOURCE LIST (All Programs)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Do you or anyone you are applying for own or have name on any resources? Cash, Checking Account, Electronic Debit Account, Savings Account, Certificate of Deposit, IRA, Stock(s), Bond(S)?"
        label="Do you or anyone you are applying for own or have name on any resources? Cash, Checking Account, Electronic Debit Account, Savings Account, Certificate of Deposit, IRA, Stock(s), Bond(S)?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="Do you or anyone you are applying for have their name on any Internet Banking Accounts? (For Example: PayPal, Venmo, Go Fund Me, CashApp, Other Accounts)"
        label="Do you or anyone you are applying for have their name on any Internet Banking Accounts? (For Example: PayPal, Venmo, Go Fund Me, CashApp, Other Accounts)"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Countable resources do not exceed $10,000 (CC Only)"
        label="Countable resources do not exceed $10,000 (CC Only)"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you or anyone you are applying for own a vehicle; this includes cars, trucks, motorcycles, boats, personal watercraft, recreational vehicles, all-terrain vehicles or other vehicles?"
        label="Do you or anyone you are applying for own a vehicle; this includes cars, trucks, motorcycles, boats, personal watercraft, recreational vehicles, all-terrain vehicles or other vehicles?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <label>
          {
            <a href="http://www.kbb.com/whats-my-car-worth/" target="_blank">
              Kelly Blue Book{" "}
            </a>
          }
        </label>
      </Row>
      <Form.Item
        name="Do you or anyone you are applying for have a trailer(s), livestock, oil rights, machinery, Land (buildings, lots, farm ground, houses), etc."
        label="Do you or anyone you are applying for have a trailer(s), livestock, oil rights, machinery, Land (buildings, lots, farm ground, houses), etc."
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you or anyone your applying for have a Trust?"
        label="Do you or anyone your applying for have a Trust?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you or anyone your applying for have an annuity?"
        label="Do you or anyone your applying for have an annuity? "
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Completed and Sent Appendix B-6 Trust/Annuity"
      >
        <Checkbox value="Completed and Sent Appendix B-6 Trust/Annuity">
          Completed and Sent Appendix B-6{" "}
          {
            <a
              href="http://content.dcf.ks.gov/EES/KEESM/Appendix/B-6_Request_for_Trust_Annuity_Clearance_05-16.pdf"
              target="_blank"
            >
              Trust/Annuity
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item name="Comments" label="Comments">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Pending for:" label="Pending for:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default RESOURCELIST;
