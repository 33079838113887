import {
  Card,
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  Anchor,
  Space,
  DatePicker,
} from "antd";
import { useState } from "react";
const { Link } = Anchor;

const VERIFICATIONSLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      //   wrapperCol={{
      //     flex: 1,
      //   }}
    >
      <Form.Item
        valuePropName="checked"
        name="Sent KEES request for verification from Document Control (desk aid)"
      >
        <Checkbox value="Sent KEES request for verification from Document Control (desk aid)">
          Sent KEES request for verification from Document Control{" "}
          {
            <a
              href="http://dcfnet.dcf.ks.gov/EES/Documents/KEESP3TrainingResources/DeskAids/Verification Detail Type Desk Aid.pdf"
              target="_blank"
            >
              (desk aid)
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Verification List Page completed, Program is selected, and Notice Sent. (V000)"
      >
        <Checkbox value="Verification List Page completed, Program is selected, and Notice Sent. (V000)">
          Verification List Page completed, Program is selected, and Notice
          Sent. (V000)
        </Checkbox>
      </Form.Item>
      <Row style={{ marginBottom: 10 }}>
        <label>*Check Distributed Documents to ensure notice sent*</label>
      </Row>
      <Form.Item name="Due Date" label="Due Date">
        <DatePicker
          style={{ width: "100%" }}
          allowClear
          format={"YYYY-MM-DD"}
        />
      </Form.Item>
    </Form>
  );
};
export default VERIFICATIONSLIST;
