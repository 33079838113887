import { Card, Row, Space, Image,List } from "antd";
import React from "react";
import lLogo from "../images/guide4.png";
import lLogo1 from "../images/guide5.png";
import lLogo2 from "../images/guide6.png";
import lLogo3 from "../images/guide7.png";
import lLogo4 from "../images/guide8.png";
import lLogo5 from "../images/guide9.png";
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";
import './style.css'
const Logs = (props) => {
const data =[
    {
        title:(
            <>
                <CloudDownloadOutlined /> Downloading Logs Guide  
            </>
        ),
        content:(
            <Row>
            <Row>
              <Space direction="vertical" >
                <p>
                  <h1><strong>Downloading Connectivity Test Result</strong></h1>
                </p>
                <Image
                    className="img2"
                    preview={false}
                    height={395}
                    src={lLogo}
                    alt="DCF Banner"
                  />
                  <br/>
                  <ol>
                    <li>
                        Open the following link:{
                        <a
                          href="https://tools.connect.aws/endpoint-test/"
                          target="_blank" rel="noreferrer"
                        > Amazon Connect Endpoint Test Utility</a>} Click on <strong> Run tests</strong>; you may leave the text box blank.
                    </li>
                    <li>Upon completion of the test, please scroll to the bottom of the page and proceed to click on "<strong>Download results</strong>."</li>
                    <li>The result is saved to your computer. Please forward this file as an attachment, accompanied by a description of the issue, to the following email address:{
                        <a
                          href="malito:Kansas-CC-Help@accenture.com"
                          target="malito:Kansas-CC-Help@accenture.com" rel="noreferrer"
                        > Kansas-CC-Help@accenture.com</a>}</li>
                  </ol>
                  <br/>
                   <p>
                  <h1><strong>Downloading CCP Logs</strong></h1>
                </p>
                <Image
                    className="img2"
                    preview={false}
                    height={395}
                    src={lLogo1}
                    alt="DCF Banner"
                  />
                  <br/>
                  <ol>
                    <li>
                        Open your Amazon Connect softphone
                    </li>
                    <li>
                        Click on the <strong> Settings </strong> icon on the top right corner
                    </li>
                    <li>
                        Click on <strong> Download logs </strong> button in the bottom of the softphone
                    </li>
                    <li>
                        The agent-log.txt file is saved to your computer. Please send an email with this file attached,
                         together with a description of the issue, to the following address:
                         {<a
                          href="malito:Kansas-CC-Help@accenture.com"
                          target="malito:Kansas-CC-Help@accenture.com" rel="noreferrer"
                        > Kansas-CC-Help@accenture.com</a>}
                    </li>
                </ol>
                <br/>
                   <p>
                  <h1><strong>Downloading HAR Log</strong></h1>
                </p>
                <Image
                    className="img2"
                    preview={false}
                    height={395}
                    src={lLogo2}
                    alt="DCF Banner"
                  />
                  <br/>
                  <ol>
                    <li>
                        <strong> Right-click in the chrome browser window and select Inspect.</strong>
                    </li>
                    <li>
                         <strong> Click the Network tab in the panel that appears </strong>
                    </li>
                    <Image
                    className="img"
                    preview={false}
                    height={195}
                    src={lLogo3}
                    alt="DCF Banner"
                  />
                  <br/>
                   <br/>
                    <li>
                        <strong> Select the Preserve Logs checkmark </strong>
                    </li>
                    <Image
                    className="img"
                    preview={false}
                    height={195}
                    src={lLogo4}
                    alt="DCF Banner"
                  />
                  <br/>
                  <br/>
                  <li>
                     <strong>Navigate to Amazon Connect and try to sign-in. If it brings the sign-in page again (duplicate login request),
                     sign-in again. The Inspect window from step 1 should be open before doing this step and should remain open until the end. </strong>
                  </li>
                  <li><strong>Click the download button {<DownloadOutlined />} (Export HAR appears when you hold the pointer over it.) </strong></li>
                <Image
                    className="img"
                    preview={false}
                    height={195}
                    src={lLogo5}
                    alt="DCF Banner"
                  />
                  <br/>
                  <br/>
                  <li> <strong>Name the file.</strong></li>
                  <li> <strong>Click Save. Email this document alongside issue description to {<a
                          href="malito:Kansas-CC-Help@accenture.com"
                          target="malito:Kansas-CC-Help@accenture.com" rel="noreferrer"
                        > Kansas-CC-Help@accenture.com</a>} </strong></li>
                  </ol>
              </Space>
            </Row>
          </Row>
        )
    }
]
     return (
    <div >
        <List 
        dataSource ={data}
        renderItem={item =>(
            <List.Item>
                <Card title = {item.title} >{item.content}</Card>
            </List.Item>
        )}
        />
    </div>
  );
}
export default Logs;