import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './pages/App';
import { store } from './store'
import { Provider } from 'react-redux'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <Provider store={store}>
    <App />
  </Provider>
  // <React.StrictMode>
  // <App />
  // </React.StrictMode>
);


