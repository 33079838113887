import { Row, Col, Form, Checkbox, Input, Select } from "antd";
import { useState } from "react";
const { Option } = Select;
const TIMELIMITSUMMARY = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          TIME LIMIT SUMMARY (All Programs)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <label>
          {
            <a
              href="http://content.dcf.ks.gov/ees/KEESM/Current/keesm2520.htm"
              target="_blank"
            >
              ABAWD Time Limit Month List
            </a>
          }
        </label>
      </Row>
      <Row>
        <label>Add ABAWD months for new time period beginning 10/1/2022.</label>
      </Row>
      <Row>
        <Col>
          <label>
            {
              <a
                href="https://dcfnet.dcf.ks.gov/EES/Documents/Resources/Training/TrainingbyTopic/Programs/ABAWD_AT_A_Glance.pdf"
                target="_blank"
              >
                ABAWD at a Glance
              </a>
            }
          </label>
        </Col>
        <Col>
          <label>
            &ensp;
            {
              <a
                href="https://dcfnet.dcf.ks.gov/EES/Documents/Resources/Training/TrainingbyTopic/Programs/ABAWD Screening Flow Chart.pdf"
                target="_blank"
              >
                ABAWD Screening
              </a>
            }
          </label>
        </Col>
        <Col>
          <label>
            &ensp;
            {
              <a
                href="https://content.dcf.ks.gov/EES/KEESM/Robo10-21/keesm2521.htm"
                target="_blank"
              >
                KEESM 2521
              </a>
            }
          </label>
        </Col>
      </Row>
      <Row>
        <label>
          <u>
            Child Care Time Limit Month List (
            {
              <a
                href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm2835.htm"
                target="_blank"
              >
                Post-Secondary Only{" "}
              </a>
            }
            )
          </u>
        </label>
      </Row>
      <Row>
        <label>Set Manual Task for Post-secondary CC Time Limit </label>
      </Row>
      <Row>
        <label>
          {
            <a
              href="https://content.dcf.ks.gov/ees/KEESWebHelp/NonMedical-KEESWebHelp/TANF_Time_Limits.htm"
              target="_blank"
            >
              Cash Aid Time Limit Month List
            </a>
          }
        </label>
      </Row>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default TIMELIMITSUMMARY;
