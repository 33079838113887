import { Select, Row, Col, Form, Input, Anchor } from "antd";
import { useState } from "react";
const { Link } = Anchor;
const { Option } = Select;
const PCSCHEDULEDLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          PARENT SCHEDULE LIST AND/ OR CHILD SCHEDULE LIST (CC Only)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="What hours of childcare do you need?"
        label="What hours of childcare do you need?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="How long does it take you to travel from the childcare provider to your work?"
        label="How long does it take you to travel from the childcare provider to your work?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="What is each child’s school schedule?"
        label="What is each child’s school schedule?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Where does each child attend school?"
        label="Where does each child attend school?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default PCSCHEDULEDLIST;
