export const interviewTemplates = [
  {
    value: "DCF is trying to reach you to complete your interview and will call you in a few minutes.",
    spanishValue:"DCF está intentando comunicarse con usted para completar su entrevista y lo llamará en unos minutos.",
    label: "DCF is trying to reach you to complete your interview and will call you in a few minutes.",
  },
  {
    value: "DCF tried to reach you to complete your interview. We will try again in a few minutes.",
    spanishValue:"DCF intentó comunicarse con usted para completar su entrevista. Lo intentaremos nuevamente en unos minutos.",
    label: "DCF tried to reach you to complete your interview. We will try again in a few minutes.",
  },
  {
    value: "This is the Kansas DCF office, we are attempting to contact you regarding your benefits case. We will attempt again in 3-5 mins. If not available, you can reach out to us at 1-888-369-4777 or visit your local office.",
    spanishValue:"Esta es la oficina del Departamento de Niños y Familias de Kansas, estamos tratando de comunicarnos con usted con respecto a los beneficios de su caso. Le volveremos a llamar otra vez en 3 a 5 minutos. Si no está disponible, puede comunicarse con nosotros al 1-888-369-4777 o visitar su oficina local.",
    label: "Benefits Retry: This is the Kansas DCF office, we are attempting to contact you regarding your benefits case. We will attempt again in 3-5 mins. If not available, you can reach out to us at 1-888-369-4777 or visit your local office.",
  }
];


