import { Select, Row, Col, Form, Radio, Input } from "antd";

const { Option } = Select;
const FAMPLANCHILDCARE = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          FAMILY PLAN – CHILD CARE LIST (CC Only)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="What is your provider’s name?"
        label="What is your provider’s name?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Row>
        <label>
          Are they a{" "}
          {
            <a
              href="https://dcfnet.dcf.ks.gov/EES/Documents/BPM/Child Care Provider Referral.docx"
              target="_blank"
            >
              relative?
            </a>
          }
        </label>
      </Row>
      <Form.Item
        name="Are they a relative?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="How long does it take you to travel from the childcare provider to your work?"
        label="How long does it take you to travel from the childcare provider to your work?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="What is the anticipated start date for this provider?"
        label="What is the anticipated start date for this provider?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default FAMPLANCHILDCARE;
