export const PHONE_NUMBER_ASSOCIATIONS = {
    HOME: "Home",
    CELLULAR: "Cell",
  };
  export const CASE_PROGRAMS = {
    WATER_ASSISTANCE: "WA",
    PRESUMPTIVE_ELIGIBILITY: "PE",
    P_EBT: "PS",
    FOSTER_CARE_ICPC: "FI",
    ICPIC: "FI",
    LIEAP: "LI",
    PRESUMPTIVE_ELIGIBILITY_ADULT: "PZ",
    PRESUMPTIVE_ELIGIBILITY_PREGNANT_WOMAN: "PG",
    FAET: ["FT", "FE"],
    INDIAN_HEALTH_SERVICE: "IHS",
    TRIBAL_HEALTH: "IHS",
    ADAP: "AP",
    YOUTH_INDEPENDENT_LIVING: "IL",
    PREMANENT_CUSTODIAN_SUBSIDY: "PC",
    PPS_VENDOR_PAYMENT: "VP",
    ADOPTION_ASSISTANCE: ["AD", "AA"],
    PRESUMPTIVE_ELIGIBILITY_CHILD: "PR",
    SSPP: "SPP",
    CHIP: "CHI",
    ESNAP: "EFS",
    TRIBAL_TANF: "TT",
    CHILD_PROTECTIVE_SERVICES: "CS",
    ADULT_PROTECTIVE_SERVICES: "AS",
    LINKAGES_ADULT_SERVICES: "LS",
    MULTIPURPOSE_SENIOR_SERVICES: "MS",
    PASNAP: "PA",
    IV_D_CHILD_SUPPORT: "IV",
    NA_SNAP: "NA",
    WORK_PROGRAMS: "WT",
    LEARN: "CL",
    GENERAL_ASSISTANCE: "GA",
    GENERAL_RELIEF: "GA",
    CAPI: "CP",
    CHILD_CARE: "CC",
    KIN_GAP: "KG",
    IMMEDIATE_NEED: "IN",
    SFAP: "CF",
    SSI: "SS",
    SSP: "SS",
    OTHER_COUNTY: "OT",
    SNAPET: "FTS",
    FOOD_ASSISTANCE: "FS",
    SSI_ONLY: "SI",
    HOMELESS: "HO",
    MEDICAL: "MC",
    IN_HOME_SUPPORTIVE_SERVICES: "IH",
    RCA: "RC",
    FOOD_DISTRIBUTION: "FD",
    FOSTER_CARE: "FC",
    DIVERSION: "DV",
    TANF: "CW",
    CHILD_sUPPORT: "CH",
    HOMELESS_PREM: "HP",
    HOMELESS_TEMP: "HT",
  };
  export const REGEX = {
    IS_PHONE_NUMBER:
      /^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
    IS_NUMERIC_ONLY: /[^0-9]/g,
  };
  export const CASE_STATUES = {
    ACTIVE: "AC",
    PENDING: "PE",
    TRANSITIONAL: "TR",
    WAITING_TO_TRANSFER: "WA",
    SANCTION: "SA",
    DEREGISTERED: "DG",
    DEFERRED: "DF",
    EXEMPT: "EX",
    INELIGIBLE: "IN",
    GOOD_CAUSE: "GC",
    NON_COMP: "NC",
    DENIED: "DE",
    DISCONTINUED: "DS",
  };
  export const LAMBDAS = {
    CUSTOMER_PROFILES: "https://gm7hkpfje7.execute-api.us-west-2.amazonaws.com/development/customerprofile",
    TEXT_TO_PHONE: "https://hd4lvbmbri.execute-api.us-west-2.amazonaws.com/ccpbeta",
    ADJUST_QUEUE: "https://8x8w9812zk.execute-api.us-west-2.amazonaws.com/prod",
    CURRENT_METRICS: "https://8x8w9812zk.execute-api.us-west-2.amazonaws.com/prod/currentMetrics",
    CURRENT_STATUS: "https://8x8w9812zk.execute-api.us-west-2.amazonaws.com/prod/currentStatus",
    SUBMIT_FORM: "https://8x8w9812zk.execute-api.us-west-2.amazonaws.com/prod/submitForm",
    FORM_PDF:
      "https://nd5wjsyzn4.execute-api.us-east-1.amazonaws.com/dev/dcf-guided-flow-cc/",
    GET_FORM_DETAILS:
      "https://6k2aarqfc7.execute-api.us-east-1.amazonaws.com/dev/getcontact?name=",
    SAVE_CONTACT:
      "https://6k2aarqfc7.execute-api.us-east-1.amazonaws.com/dev/saveContact",
  };
  export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  export const LIST_FORMS = [
    "TANF/CC/FOOD ASSISTANCE PROGRAM",
    "RIGHTS AND RESPONSIBILITIES",
    "REGISTRATION",
    "TANF Drug Testing Program Requirements",
    "TANF ONLY and WORK PROGRAM COOP",
    "CHILD CARE ONLY",
    "CC/TANF ONLY",
    "VOTER REGISTRATION",
    "PROGRAM WITHDRAW",
    "CASE SUMMARY (All Programs)",
    "AUTHORIZED REP",
    "CONTACT SUMMARY SCREEN (All Programs)",
    "REPORTING PAGE (Reviews/IR ONLY)",
    "INDIVIDUAL DEMOGRAPHICS SCREEN (All Programs)",
    "CITIZENSHIP/ IDENTITY LIST (All Programs)",
    "HOUSEHOLD STATUS LIST",
    "RELATIONSHIP LIST (All Programs)",
    "PREGNANCY LIST (All Programs)",
    "OTHER PROGRAM LIST (All Programs)",
    "NON-COMPLIANCE LIST (All Programs)",
    "TIME LIMIT SUMMARY (All Programs)",
    "PURCHASE AND PREPARE LIST (FA)",
    "SCHOOL ATTENDANCE LIST (All Programs)",
    "LIVING ARRANGEMENTS",
    "NONCUSTODIAL PARENT LIST (All Programs)",
    "MEDICAL CONDITION LIST (FA, TANF)",
    "EMPLOYMENT LIST /INCOME LIST",
    "RESOURCE LIST (All Programs)",
    "SHELTER - EXPENSE LIST (FA, TANF)",
    "DEPENDENT CARE/ CSS - EXPENSE LIST (FA, TANF)",
    "MEDICAL - EXPENSE LIST (FA, TANF)",
    "MEDICARE EXPENSE LIST (FA Only)",
    "CHILD CARE NEED LIST – PARENT AND/ OR CHILD CARE NEED LIST – CHILD (CC Only)",
    "PARENT SCHEDULE LIST AND/ OR CHILD SCHEDULE LIST (CC Only)",
    "FAMILY PLAN – CHILD CARE LIST (CC Only)",
    "TANF Suspicion Based Drug Testing (Only those who meet suspicion-based indicators)",
    "DOMESTIC VIOLENCE, SEXUAL ASSAULT, OR STALKING SCREENING (REQUIRED)",
    "EBT",
    "EXPEDITED STATUS",
    "MANDATORY ABAWD / E&T / WORK REGISTRANT SCRIPTS",
    "VERIFICATIONS LIST",
    "TODO",
    "RUN EDBC",
    "NARRATIVE",
  ];
  