import { useState } from "react";
import axios from "axios";
import useUpdateEffect from "./useUpdateEffect";

//handles the headers call for the api
function handleHeaders(
  contentType = "application/json",
  apiToken = null,
  authorizationToken = null
) {
  switch ((apiToken !== null, authorizationToken !== null)) {
    case (true, true):
      return {
        "Content-Type": contentType,
        "x-api-key": apiToken,
        authorizationToken: authorizationToken,
      };
    case (false, true):
      return {
        "Content-Type": contentType,
        authorizationToken: authorizationToken,
      };

    case (true, false):
      return {
        "Content-Type": contentType,
        "x-api-key": apiToken,
      };
    case (false, false):
      return {
        "Content-Type": contentType,
      };
    default:
      return {
        "Content-Type": contentType,
        "x-api-key": apiToken,
        authorizationToken: authorizationToken,
      };
  }
}
/**
 * calls api with input url.
 * Note: null value for url will not be attempted.
 * @param {string} url
 * @returns
 */

export default function useUpdateFetch(
  url,
  payload = null,
  method = "POST",
  contentType = "application/json",
  apiToken = null,
  authorizationToken = null
) {
  const [data, setData] = useState(null);
  const [isFecthingData, setIsFetchingData] = useState(null);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState(null);
  useUpdateEffect(() => {
    console.log("URL IN UPDATEFETCH: ", url);
    console.log("Payload IN UPDATEFETCH: ", payload);

    console.log("API TOKEN: ", apiToken)
    console.log("Authorization TOKEN: ", authorizationToken)

    const abortCont = new AbortController();
    if (url === null) {
      console.log("URL IS NULL SO FETCHING DATA IS FALSE");
      setIsFetchingData(false);
      return;
    }
    setIsFetchingData(true);
    console.log("URL IS NOT NULL SO FETCHING DATA IS TRUE");

    console.log("HEADERS: ",handleHeaders(contentType, apiToken, authorizationToken))
    console.log("METHOD: ", method)
    console.log("URL: ", url)
    console.log("DATA PAYLOAD: ", payload)

    axios({
      method: method,
      baseURL: url,
      data: payload,
      headers: handleHeaders(contentType, apiToken, authorizationToken),
    })
      .then((response) => {
        setIsFetchingData(false);
        console.log("Response: ", response);
        console.log("Response Data JJNVIAO: ", response.data);
        console.log("ERROR TYPE JJNVIAO: ", response.data.errorType);
        if (response.data.errorType != null) {
          setData(null);
          setIsFetchingData(false);
          setError(error);
        } else {
          if (
            response.data.errorMessage ==
            "None of the URIs are online for Business service getCustomerProfile"
          ) {
            console.log("VNUEWVUIWE: ", response.data.errorMessage);
            setData(null);
            setIsFetchingData(false);
            setError(error);
          } else {
            console.log("VNUEWVUIWE: no error message in Data")
            setData(response.data);
            setError(null);
            setRequest(response.config);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        // if (error.name === 'AbortError') {
        // } else {
        // auto catches network / connection error
        setIsFetchingData(false);
        setError(error);
        //}
      });
    // abort the fetchs
    return () => abortCont.abort();
  }, [url, payload]);

  console.log("Data: ", data);
  console.log("Is fetching data: ", isFecthingData);
  console.log("Error: ", error);
  console.log("Request: ", request);
  return { data, isFecthingData, error, request };
}
