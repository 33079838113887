import {
  Card,
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  Anchor,
  Space,
  Badge,
} from "antd";
import { useState } from "react";
const { Link } = Anchor;

const ICMAWRS = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      //   wrapperCol={{
      //     flex: 1,
      //   }}
    >
      <Form.Item
        valuePropName="checked"
        name="Read and Reviewed Mandatory ABAWD / E&T / Work Registrant Scripts (E-26) with Client"
      >
        <Checkbox value="Read and Reviewed Mandatory ABAWD / E&T / Work Registrant Scripts (E-26) with Client">
          {
            <a
              href="https://content.dcf.ks.gov/ees/KEESM/Appendix/E-26 - Consolidated Work Requirements (English).pdf"
              target="_blank"
            >
              Mandatory ABAWD / E&T / Work Registrant Scripts
            </a>
          }
          (E-26) with Client
        </Checkbox>
      </Form.Item>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default ICMAWRS;
