import { Row, Col, Form, Input, Select, Radio } from "antd";
const { Option } = Select;
const OTHERPROGRAMLIST = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      style={{ maxWidth: "100%" }}
    >
      <Row>
        <Col span={4}>Screen Name: </Col>
        <Col span={14} style={{ fontWeight: "bold" }}>
          OTHER PROGRAM LIST (All Programs)
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Is anyone in the HH receiving or have received benefits in another state?"
        label="Is anyone in the HH receiving or have received benefits in another state?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="If so where and when?" label="If so where and when?">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Are any household members receiving tribal commodities?"
        label="Are any household members receiving tribal commodities?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <Form.Item name="Comments" label="Comments">
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="Pending for:" label="Pending for:">
          <Input style={{ width: "100%" }} />
        </Form.Item>
      </Row>
    </Form>
  );
};
export default OTHERPROGRAMLIST;
