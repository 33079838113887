import {
  Card,
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  Anchor,
  Space,
  Badge,
} from "antd";
import { useState } from "react";
const { Link } = Anchor;

const TANFSUSPENSIONBASED = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="left"
      layout="horizontal"
      labelWrap
      //   wrapperCol={{
      //     flex: 1,
      //   }}
    >
      <Row>
        <label>
          In accordance with Kansas State Statute 39-709 regarding
          suspicion-based drug testing, you have demonstrated certain indicators
          that require DCF to refer you for drug testing at
        </label>
      </Row>
      <Form.Item
        name="In accordance with Kansas State Statute 39-709 regarding suspicion-based drug testing, you have demonstrated certain indicators that require DCF to refer you for drug testing at"
        // label=""
      >
        <Input style={{ width: "100%" }} placeholder="facility name" />
      </Form.Item>
      <Form.Item name="DCF will be contacting" label="DCF will be contacting">
        <Input style={{ width: "100%" }} placeholder="facility name" />
      </Form.Item>
      <label>
        facility, to schedule an appointment with you today. You will need to
        take a photo ID with you to the testing facility. If you fail to attend
        or refuse to test, the following consequences may apply:
      </label>
      <Row>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="First Failure/Refusal: 6 months of ineligibility for yourself and you are required to undergo testing prior to regaining eligibility."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Second Failure/Refusal: 12 months of ineligibility for yourself and you are required to undergo testing prior to regaining eligibility."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Third Failure/Refusal: Lifetime ineligibility for yourself."
          />
        </Space>
      </Row>
      <Row>
        <label>
          A comparable disqualification will also apply to the food assistance
          case if you are receiving food assistance and TANF at the time of
          refusal or failure. The person who failed or refused to test will be
          ineligible and the remaining household members can get food assistance
          if otherwise eligible.
        </label>
      </Row>
      <Form.Item name="Observed Indicators:" label="Observed Indicators:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Comments:" label="Comments:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Client Response:" label="Client Response:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Special Accommodations Needed Per Client Request:"
        label="Special Accommodations Needed Per Client Request:"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default TANFSUSPENSIONBASED;
