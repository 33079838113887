import { Table, Input, Space, Button }  from 'antd';
import { useRef,useState } from 'react';
import { CASE_STATUES}  from '../../../literals';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import PhoneIcon from '../components/PhoneIcon';
const TopLevelAssociateTable = (props) => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    
    const expandedRowRender = (profile) => {
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
          confirm();
          setSearchText(selectedKeys[0]);
          setSearchedColumn(dataIndex);
        };
    
        const handleReset = (clearFilters) => {
          clearFilters();
          setSearchText('');
        };
    
        const getColumnSearchProps = (dataIndex) => ({
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
              style={{
                padding: 8,
              }}
            >
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{
                  marginBottom: 8,
                  display: 'block',
                }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{
                    width: 90,
                  }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => clearFilters && handleReset(clearFilters)}
                  size="small"
                  style={{
                    width: 90,
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({
                      closeDropdown: false,
                    });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          ),
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : '#4a4a4a',
              }}
            />
          ),
          onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          },
          render: (text) =>
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ) : (
              text
            ),
        });
        const columns = [
          {
            title: 'Case Number',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            ...getColumnSearchProps('caseNumber')
          },
          {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'programCode',
            ...getColumnSearchProps('programCode')
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status')
          },
          {
            title: 'Start Date',
            dataIndex: 'beginDate',
            key: 'beginDate',
            defaultSortOrder: 'descend',
            sorter: (c1, c2) => new Date(c1.beginDate) - new Date(c2.beginDate),
            ...getColumnSearchProps('beginDate')
          },
          {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            defaultSortOrder: 'descend',
            sorter: (c1, c2) => new Date(c1.endDate) - new Date(c2.endDate),
            ...getColumnSearchProps('endDate')
          },
          // {
          //   title: 'Case Zip Code',
          //   dataIndex: 'zipCode',
          //   key: 'zipCode',
          //   ...getColumnSearchProps('zipCode')
          // }
        ];
        return <Table  
            rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            columns={columns} 
            dataSource={profile.cases} 
            pagination={true} 
            />;
    };
    const columns = [
        {
          title: 'First Name',
          dataIndex: 'firstName',
          key: 'firstName',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastName',
          key: 'lastName',
        },
        
        {
          title: 'Date of Birth',
          dataIndex: 'dob',
          key: 'dob'
        },
        {
          title: 'Last 4 SSN',
          dataIndex: 'ssn',
          key: 'ssn',
        },
        {
          title: 'Phone Association',
          dataIndex: 'phoneAssociation',
          key: 'phoneAssociation',
          render: (_, person) => 
          {
            return <Space align="center">
                {person.phoneAssociation.map((phone) =>  <PhoneIcon phoneType ={phone}/>)}
              </Space>
          }
        },
        {
          title: 'Zip Code',
          dataIndex: 'zipCode',
          key: 'zipCode'
        },
        {
          title: 'Cases',
          key: 'caseStatus',
          render: (_, person) => 
          {
              if(person.cases.length ===0)
              {
                return <div>{person.firstName} has no cases</div>
              }
              if(person. cases.some(c => c.status !== CASE_STATUES.DISCONTINUED))
              {
                return <div>{person.firstName} has active cases</div>;
              }
              return <div>{person.firstName} has no active cases</div>
          }
        },
    ];



    return (<Table 
        rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        loading={props.loading}
        dataSource={props.profiles}
        pagination={props.pagination} />);
}
 
export default TopLevelAssociateTable;
