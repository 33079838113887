export const fcccTemplates = [
    {
        value: "Foster Care Child Care (FCCC) unit is attempting to contact you. If we are unable to connect with you, please call us at 785-368-8594.",
        spanishValue:"La Unidad de Guardería de Adopción Temporal (Foster Care) está tratando de comunicarse con usted. Si no pudiéramos comunicarnos con usted, por favor llámenos al 785-368-8594.",
        label: "FCCC: Foster Care Child Care (FCCC) unit is attempting to contact you. If we are unable to connect with you, please call us at 785-368-8594.",
    },
    {
        value: "Thank you for contacting the Foster Care Child Care Unit. Our email address is DCF.FosterCareCC@ks.gov. You may also call us at 785-368-8594 or visit our website https://ksqualitynetwork.org/families/foster-care-child-care-subsidy for more information.",
        spanishValue:"Gracias por comunicarse con la Unidad de Guardería de Adopción Temporal (Foster Care). Nuestro correo electrónico es DCF.FosterCareCC@ks.gov. También puede llamarnos al 785-368-8594 o visitar nuestra página web https://ksqualitynetwork.org/families/foster-care-child-care-subsidy para mayor información. ",
        label: "FCCC: Thank you for contacting the Foster Care Child Care Unit. Our email address is DCF.FosterCareCC@ks.gov. You may also call us at 785-368-8594 or visit our website https://ksqualitynetwork.org/families/foster-care-child-care-subsidy for more information.",
    },
    {
        value: "Please call ebtEDGE Customer Service to 1-800-997-6666 or visit their website at ebtEDGE.com.",
        spanishValue:"Por favor, llame al Servicio al Cliente de ebtEDGE al 1-800-997-6666 o visite su página web  ebtEDGE.com.",
        label: "FCCC: Please call ebtEDGE Customer Service to 1-800-997-6666 or visit their website at ebtEDGE.com.",
    },
    {
        value: "The link to the www.ksqualitynetwork.org/parent-provider-handbook.",
        spanishValue:"Enlace al Manual de padres y proveedores www.ksqualitynetwork.org/parent-provider-handbook. ",
        label: "FCCC: The link to the www.ksqualitynetwork.org/parent-provider-handbook.",
    },
    {
        value: "FCCC Application https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100FC-CC_Application.pdf.",
        spanishValue:"Formulario de aplicación a FCCC https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100FC-CC_Application.pdf.",
        label: "FCCC: FCCC Application https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100FC-CC_Application.pdf.",
    },
    {
        value: "Spanish FCCC Application https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100FCS-CC_Application.pdf.",
        spanishValue:"Formulario de aplicación a FCCC en español https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100FCS-CC_Application.pdf",
        label: "FCCC: Spanish FCCC Application https://content.dcf.ks.gov/ees/KEESM/Forms/ES-3100FCS-CC_Application.pdf.",
    },
    {
        value: "FCCC Change form https://content.dcf.ks.gov/ees/KEESM/Forms/ES-1512FC-CC%20Change%20Form.pdf.",
        spanishValue:"Formulario de cambio de FCCC https://content.dcf.ks.gov/ees/KEESM/Forms/ES-1512FC-CC%20Change%20Form.pdf ",
        label: "FCCC: FCCC Change form https://content.dcf.ks.gov/ees/KEESM/Forms/ES-1512FC-CC%20Change%20Form.pdf.",
    },
    {
        value: "Spanish FCCC Change Form https://content.dcf.ks.gov/ees/KEESM/Forms/1512FCS-CC_ChangeForm6-20.docx.",
        spanishValue:"Formulario de cambio de FCCC en español https://content.dcf.ks.gov/ees/KEESM/Forms/1512FCS-CC_ChangeForm6-20.docx ",
        label: "FCCC: Spanish FCCC Change Form https://content.dcf.ks.gov/ees/KEESM/Forms/1512FCS-CC_ChangeForm6-20.docx.",
    },
    {
        value: "Please submit invoices or timesheets to the Child Care Exception Payment Program (CCEPP) at DCF.FCCCEBTException@ks.gov. They will work with you and your provider to determine if CCEPP funding will cover the child care expense.",
        spanishValue:"Por favor, envíe las facturas o planillas de horas al Programa de pagos excepcionales de guardería (CCEPP, por sus siglas en inglés) a DCF.FCCCEBTException@ks.gov. Ellos trabajarán con usted y su proveedor para determinar si los fondos del CCEPP cubrirán el costo de la guardería.",
        label: "CCEPP: Please submit invoices or timesheets to the Child Care Exception Payment Program (CCEPP) at DCF.FCCCEBTException@ks.gov. They will work with you and your provider to determine if CCEPP funding will cover the child care expense.",
    },
    {
        value: "Please visit https://www.dcf.ks.gov/services/PPS/Pages/PPSservices.aspx and scroll down to Foster Care Services-Child Care Exception Payment Program (CCEPP) to get the CCEPP Getting Started Guide and other forms.  All questions and forms for CCEPP should be sent to DCF.FCCCEBTException@ks.gov.",
        spanishValue:"Por favor, visite https://www.dcf.ks.gov/services/PPS/Pages/PPSservices.aspx y desplace la pantalla hacia abajo hasta Programa de pagos excepcionales de guardería  (CCEPP, por sus siglas en inglés) para obtener la Guía inicial del CCEPP y otros formularios. Todas las preguntas y formularios dirigidos al CCEPP deben enviarse a DCF.FCCCEBTException@ks.gov. ",
        label: "CCEPP: Please visit https://www.dcf.ks.gov/services/PPS/Pages/PPSservices.aspx and scroll down to Foster Care Services-Child Care Exception Payment Program (CCEPP) to get the CCEPP Getting Started Guide and other forms.  All questions and forms for CCEPP should be sent to DCF.FCCCEBTException@ks.gov.",
    },
    {
        value: "If interested in becoming a KDHE licensed Child Care Provider or enrolled DCF Child Care Provider please visit https://ksqualitynetwork.org/new-provider/.",
        spanishValue:"Si está interesado en ser un Proveedor de guardería licenciado por el KDHE o estar inscrito como Proveedor de guardería del DCF, por favor visite https://ksqualitynetwork.org/new-provider/. ",
        label: "Provider Info: If interested in becoming a KDHE licensed Child Care Provider or enrolled DCF Child Care Provider please visit https://ksqualitynetwork.org/new-provider/.",
    },
    {
        value: "To find a Child Care Provider in your area, please contact Child Care Aware of Kansas directly at www.ks.childcareaware.org or call 1-877-678-2548.",
        spanishValue:"Para encontrar un proveedor de cuidado infantil en su área, comuníquese con Child Care Aware of Kansas directamente en www.ks.childcareaware.org o llame al 1-877-678-2548.",
        label: "Provider Info: To find a Child Care Provider in your area, please contact Child Care Aware of Kansas directly at www.ks.childcareaware.org or call 1-877-678-2548.",
    },
    {
        value: "To apply for Food Assistance, Cash, or Child Care for children not in foster care visit DCF’s website at www.dcf.ks.gov, where you can find instructions to apply online.",
        spanishValue:"Para aplicar para Asistencia alimentaria en efectivo o de guardería para niños que no están en adopción temporal (foster care) visite la página web del DCF www.dcf.ks.gov, donde puede encontrar instrucciones para aplicar en línea.",
        label: "Apply: To apply for Food Assistance, Cash, or Child Care for children not in foster care visit DCF’s website at www.dcf.ks.gov, where you can find instructions to apply online.",
    },
    {
        value: "You can send any additional information to DCF.AtchisonEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.AtchisonEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.AtchisonEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ChanuteEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ChanuteEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ChanuteEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ColbyEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ColbyEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ColbyEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ColumbusEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ColumbusEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ColumbusEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ConcordiaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ConcordiaEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ConcordiaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.DodgeCityEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.DodgeCityEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.DodgeCityEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ElDoradoEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ElDoradoEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ElDoradoEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.EmporiaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.EmporiaEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.EmporiaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.FtScottEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.FortScottEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.FtScottEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.GardenCityEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.GardenCityEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.GardenCityEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.GoodlandEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.GoodlandEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.GoodlandEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.GreatBendEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.GreatBendEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.GreatBendEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.HaysEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.HaysEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.HaysEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.HiawathaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.HiawathaEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.HiawathaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.HutchinsonEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.HutchinsonEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.HutchinsonEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.IndependenceEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.IndependenceEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.IndependenceEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.JunctionCityEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.JunctionCityEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.JunctionCityEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.KansasCityEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.KansasCityEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.KansasCityEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.LawrenceEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.LawrenceEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.LawrenceEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.LeavenworthEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.LeavenworthEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.LeavenworthEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.LiberalEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.LiberalEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.LiberalEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ManhattanEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ManhattanEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ManhattanEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.MarysvilleEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.MarysvilleEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.MarysvilleEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.McPhersonEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.McPhersonEES@ks.gov. ",
        label: "Additional Information: You can send any additional information to DCF.McPhersonEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.NewtonEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.NewtonEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.NewtonEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.OsawatomieEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.OsawatomieEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.OsawatomieEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.OttawaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.OttawaEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.OttawaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.OverlandParkEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.OverlandParkEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.OverlandParkEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.ParsonsEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.ParsonsEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.ParsonsEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.PhillipsburgEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.PhillipsburgEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.PhillipsburgEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.PittsburgEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.PittsburgEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.PittsburgEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.PrattEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.PrattEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.PrattEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.SalinaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.SalinaEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.SalinaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.TopekaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.TopekaEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.TopekaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.WichitaEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.WichitaEES@ks.gov. ",
        label: "Additional Information: You can send any additional information to DCF.WichitaEES@ks.gov.",
      },
      {
        value: "You can send any additional information to DCF.WinfieldEES@ks.gov.",
        spanishValue:"Puede enviar información a su oficina local del DCF a:  DCF.WinfieldEES@ks.gov.",
        label: "Additional Information: You can send any additional information to DCF.WinfieldEES@ks.gov.",
      }
  ];
  