import { Row, Col, Form, Checkbox, Input, Select } from "antd";
const { Option } = Select;
const HOUSEHOLDSTATUSLIST = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={6}>Screen Name: </Col>
        <Col span={16} style={{ fontWeight: "bold" }}>
          HOUSEHOLD STATUS LIST
        </Col>
        <Col span={2}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 150 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item valuePropName="checked" name="TANF Shared living entered:">
        <Checkbox value="TANF Shared living entered">
          TANF Shared living entered
        </Checkbox>
      </Form.Item>
      <Row>
        <label>
          Confirm Household Status for all HH members requesting benefits
        </label>
      </Row>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default HOUSEHOLDSTATUSLIST;
